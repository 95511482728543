/*--------------------------------------------------------------
6. Button
----------------------------------------------------------------*/
.st-btn.st-style1,
.st-btn.st-style2 {
  display: inline-flex;
  justify-content: center;
  line-height: 1.6em;
  font-weight: 600;
  padding: 0.76em 2.5em;
  border-radius: 7px;
  border: none;
  outline: none;
  cursor: pointer;
  transition: all 0.4s ease;

  &.st-rounded {
    border-radius: 1.6em;
  }
}

.st-btn.st-style1.st-type1 {
  border-radius: 1.6em;
}
.st-apply {
  margin-bottom: 4rem;
}

.st-btn.st-style2 {
  border: 1px solid;
  padding: 0.734em 3.2em;
}

.st-btn.st-style1:hover,
.st-btn.st-style2:hover {
  transform: translateY(-1px);
  box-shadow: 0px 5px 10px 0px rgba(68, 68, 68, 0.1);
}

.st-btn.st-style1.st-color1 {
  background-color: $blue;
  color: #fff;
}

.st-btn.st-style1.st-color2 {
  background: $black1;
  color: #fff;
}

.st-btn.st-style1.st-color3 {
  background: #fff;
  color: #222;
}

.st-btn.st-style1.st-color4 {
  background-color: $pink;
  color: #fff;
}

.st-btn.st-style1.st-color5 {
  background: linear-gradient(
    45deg,
    rgba(20, 70, 234, 1) 0%,
    rgba(3, 220, 236, 1) 88%,
    rgba(3, 220, 236, 1) 100%
  );
  color: #fff;
}

.st-btn.st-style1.st-color6 {
  background-color: $light-blue;
  color: #fff;
}

.st-btn.st-style2 {
  &.st-color1 {
    background-color: $white;
    border-color: $blue;
    color: $blue;

    &:hover {
      background-color: $blue;
      color: #fff;
    }
  }

  &.st-color2 {
    border-color: #fff;
    color: #fff;

    &:hover {
      background-color: #fff;
      color: #222;
    }
  }

  &.st-color3 {
    background-color: $white;
    border-color: $light-blue;
    color: $light-blue;

    &:hover {
      background-color: $light-blue;
      color: #fff;
    }
  }
  &.st-color4 {
    background-color: $white;
    border-color: $pink;
    color: $pink;

    &:hover {
      background-color: $pink;
      color: #fff;
    }
  }
}

.st-btn.st-size-medium {
  padding: 0.6em 1.8em;
}

.st-hero-btn-group {
  .st-btn:not(:last-child) {
    margin-right: 12px;
  }
}

@media screen and (max-width: 991px) {
  .st-btn.st-style1,
  .st-btn.st-style2 {
    font-size: 14px;
    padding: 0.72em 1.8em;
  }
}
